import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { InternalCommunicationService } from './../internal-communication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { KenticoService } from '../kentico.service';
import { environment } from '../../environments/environment';
import { PitUtils } from '../validators/pit-utils';
@Component({
  selector: 'app-refund-modal-not-found',
  templateUrl: './refund-modal-not-found.component.html',
  styleUrls: ['./refund-modal-not-found.component.scss']
})
export class RefundModalNotFoundComponent implements OnInit {
  cmsModalSection = Object;
  @Input() cultureKentico: string;
  lang: string;
  loaded = false;
  @Input() texto: string;
  @Input() textoStc: string;
  textToShow: string;
  textoStcToShow: string;
  noShowRedirectUrl: string;
  urlRedirect: string;
  @Input() modalEmail;
  @Input() emailOnRedirect: string;
  @Input() modalPnr: string;
  subscription: Subscription;
  baseUrl = environment.url;

  constructor(private internalMsg: InternalCommunicationService, private route: ActivatedRoute, private router: Router, private kenticoService: KenticoService
    ) {
  }
  async ngOnInit() {
     await this.getListFormCMSSections();
     // console.log('email ', this.modalEmail);

     if(this.texto && this.texto == 'message_redirect' || this.texto == 'msj_redirect_chilecompra'){
      setTimeout(function () {
        console.log('redirect a: ', this.urlRedirect)
        window.location.href = this.urlRedirect;
      }.bind(this), environment.modalRedirect.secondsTimeout);
    }
  }  
  
  onModalClose() {
    this.internalMsg.sendMessage('true');
    console.log('modal cerrado');
    const lang = this.route.snapshot.params.lang;
    const country = this.route.snapshot.params.country;
    this.texto == 'message_redirect'
    if (this.route.snapshot.params.redirectToken && this.texto == 'message_redirect') {
      this.route.snapshot.params.redirectToken
    }else if(this.route.snapshot.params.redirectToken){
      window.location.assign(`${country}/${lang}/devoluciones/`);
    }
  }
  async getListFormCMSSections(): Promise<any> {
    const retrieveElements = ['modal_title', 'modal_button'];

    if (this.texto && this.texto !== '') {
      retrieveElements.push(this.texto);
    }

    if (this.textoStc && this.textoStc !== '') {
      retrieveElements.push(this.textoStc);
    }

    if (this.texto && this.texto == 'error_msg_pvc') {
      retrieveElements.push('modal_pvc_button');
      retrieveElements.push('modal_pvc_title');
    }

    if (this.texto && this.texto == 'message_redirect') {
      retrieveElements.push('url_redirect');
    }

    if (this.texto && this.texto == 'msj_redirect_chilecompra') {
      retrieveElements.push(environment.production ? 'url_redirect_chilecompra': 'url_redirect_chilecompra_test');
    }

    return new Promise<void>((resolve, reject) => {
      this.kenticoService.getItems('devoluciones', this.cultureKentico, retrieveElements)
      .then(resp => {
        console.log('Respuesta Kentico MODAL PVC:', resp);
        this.cmsModalSection = resp ? resp : {};
        this.textToShow = this.texto ? this.cmsModalSection[this.texto] : '';
        console.log('textToShow: ')

        this.textoStcToShow = this.textoStc ? this.cmsModalSection[this.textoStc] : '';
        console.log('textoStc: ',this.textoStc )
        console.log('textoStcToShow: ', this.textoStcToShow)

        if (this.texto == 'error_msg_pvc'){
          this.cmsModalSection['modal_button'] = resp.modal_pvc_button
          this.cmsModalSection['modal_title'] = resp.modal_pvc_title
        }

        if(this.texto === 'message_redirect'){
          this.noShowRedirectUrl = resp.URL_redirect;
          console.log('this.noShowRedirectUrl: ',this.noShowRedirectUrl)
          console.log('this.emailOnRedirect: ', this.emailOnRedirect)
          console.log('this.modalPnr: ',this.modalPnr)
          this.urlRedirect = PitUtils.replaceEmailAndPnr(this.noShowRedirectUrl, this.emailOnRedirect, this.modalPnr);
          console.log('this.urlRedirect: ',this.urlRedirect)
        }

        if(this.texto === 'msj_redirect_chilecompra'){
          this.textToShow = this.cmsModalSection[this.texto];   
          this.urlRedirect =  environment.production ? resp.url_redirect_chilecompra : resp.url_redirect_chilecompra_test;
          this.urlRedirect = this.urlRedirect.replace('##EMAIL##',this.modalEmail);
          this.urlRedirect = this.urlRedirect.replace('##PNR##',this.modalPnr);
        }

        if (typeof this.modalEmail !== 'undefined') {
          if (this.textToShow !== '') {
            this.textToShow = this.textToShow.replace('<EMAIL>', this.modalEmail);
          }
          if (this.textoStcToShow !== '') {
            this.textoStcToShow = this.textoStcToShow.replace('<EMAIL>', this.modalEmail);
          }

        }

        this.loaded = true;

        resolve();
      });
    });
  }
}
